import { createStore, Event } from 'effector';

import { ColumnType, DashboardView } from 'apps/request/types';
import { applyReducers } from 'effector-utils';

import {
  showAppModal,
  hideAppModal,
  showAppMenu,
  hideAppMenu,
  setDashboardView,
  setUrgentRequest,
  removeUrgentRequest,
  enableMobileViewSelector,
  disableMobileViewSelector,
  showAddressHeader,
  hideAddressHeader,
} from './actions';

type State = {
  isAppModalVisible: boolean;
  appModalContent: JSX.Element | null;
  appModalCloseTimeout: number | null;
  appModalStyleOverrides: string | null;
  isAppMenuVisible: boolean;
  dashboardView: DashboardView;
  showMobileViewSelector: boolean;
  displayAddressInHeader: boolean;
  urgentRequest: {
    toGarage: boolean;
    toHotel: boolean;
  };
};

const initialState: State = {
  isAppModalVisible: false,
  appModalContent: null,
  appModalCloseTimeout: null,
  appModalStyleOverrides: null,
  isAppMenuVisible: false,
  dashboardView: DashboardView.TODO,
  showMobileViewSelector: false,
  displayAddressInHeader: false,
  urgentRequest: {
    toGarage: false,
    toHotel: false,
  },
};

const store = createStore(initialState);

type Reducers = {
  [actionName: string]: {
    action:
      | Event<void>
      | Event<boolean>
      | Event<{ content: JSX.Element; timeout?: number }>
      | Event<Parameters<typeof hideAppModal>[0]>
      | Event<{ view: DashboardView }>
      | Event<{ whichColumn: ColumnType }>;
    reducer: (
      state: State,
      payload: boolean &
        Parameters<typeof showAppModal>[0] &
        Parameters<typeof setDashboardView>[0] &
        Parameters<typeof setUrgentRequest>[0] &
        Parameters<typeof removeUrgentRequest>[0],
    ) => State;
  };
};

export const reducers: Reducers = {
  showAppModal: {
    action: showAppModal,
    reducer: (state, { content, timeout, styleOverrides }) => ({
      ...state,
      appModalContent: content,
      isAppModalVisible: true,
      appModalCloseTimeout: timeout || null,
      appModalStyleOverrides: styleOverrides || null,
    }),
  },
  hideAppModal: {
    action: hideAppModal,
    reducer: (state) => ({
      ...state,
      isAppModalVisible: false,
      appModalStyleOverrides: null,
    }),
  },
  showAppMenu: {
    action: showAppMenu,
    reducer: (state) => ({
      ...state,
      isAppMenuVisible: true,
    }),
  },
  hideAppMenu: {
    action: hideAppMenu,
    reducer: (state) => ({
      ...state,
      isAppMenuVisible: false,
    }),
  },
  setDashboardView: {
    action: setDashboardView,
    reducer: (state, { view }) => ({
      ...state,
      dashboardView: view,
    }),
  },
  setUrgentRequest: {
    action: setUrgentRequest,
    reducer: (state, { whichColumn }) => {
      if (whichColumn === ColumnType.CarsToGarage) {
        return {
          ...state,
          urgentRequest: {
            ...state.urgentRequest,
            toGarage: true,
          },
        };
      }

      if (whichColumn === ColumnType.CarsToHotel) {
        return {
          ...state,
          urgentRequest: {
            ...state.urgentRequest,
            toHotel: true,
          },
        };
      }

      return state;
    },
  },
  removeUrgentRequest: {
    action: removeUrgentRequest,
    reducer: (state, { whichColumn }) => {
      if (whichColumn === ColumnType.CarsToGarage) {
        return {
          ...state,
          urgentRequest: {
            ...state.urgentRequest,
            toGarage: false,
          },
        };
      }

      if (whichColumn === ColumnType.CarsToHotel) {
        return {
          ...state,
          urgentRequest: {
            ...state.urgentRequest,
            toHotel: false,
          },
        };
      }

      return state;
    },
  },

  enableMobileViewSelector: {
    action: enableMobileViewSelector,
    reducer: (state) => ({
      ...state,
      showMobileViewSelector: true,
    }),
  },

  disableMobileViewSelector: {
    action: disableMobileViewSelector,
    reducer: (state) => ({
      ...state,
      showMobileViewSelector: false,
    }),
  },

  showAddressHeader: {
    action: showAddressHeader,
    reducer: (state) => ({
      ...state,
      displayAddressInHeader: true,
    }),
  },

  hideAddressHeader: {
    action: hideAddressHeader,
    reducer: (state) => ({
      ...state,
      displayAddressInHeader: false,
    }),
  },
};

export default applyReducers({ store, reducers });
