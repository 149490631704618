import { APIResponse } from '@m/utils/http';
import { createStore, Effect } from 'effector';

import { applyReducers, DoneHandler } from 'effector-utils';

import { getIntakePartnerDetailsByUserId } from './actions';

type State = {
  intakePartnerDetails: {
    id: number;
    name: string;
    enterpriseId?: number;
    siteId?: number;
    address?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    phoneNumber?: string;
  } | null;
};

const initialState: State = {
  intakePartnerDetails: null,
};

const store = createStore(initialState);

type Reducers = {
  getIntakePartnerDetailsByUserId: {
    action: Effect<Parameters<typeof getIntakePartnerDetailsByUserId>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getIntakePartnerDetailsByUserId>[0], State>;
  };
};

export const reducers: Reducers = {
  getIntakePartnerDetailsByUserId: {
    action: getIntakePartnerDetailsByUserId,
    done: (state, { result: { success, data } }) => ({
      ...state,
      intakePartnerDetails: success ? data : null,
    }),
  },
};

export default applyReducers({ store, reducers });
