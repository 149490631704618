import { IdNameObject } from 'types';

import { LicensePlate } from 'types/api';

export enum ColumnType {
  CarsParked = 'cars parked',
  CarsToGarage = 'cars to garage',
  CarsToHotel = 'cars to hotel',
}

export enum RequestStatus {
  Requested = 'requested',
  InProgress = 'in progress',
  Complete = 'complete',
  Parked = 'parked',
}

export enum HospitalityStayStatus {
  PickUpRequested = 'Pick Up Requested',
  PickUpInProgress = 'Pick Up In Progress',
  Parked = 'Parked',
  DropOffRequested = 'Drop Off Requested',
  DropOffInProgress = 'Drop Off In Progress',
  DroppedOff = 'Dropped Off',
  Completed = 'Completed',
}

export enum HospitalityStayType {
  Transient = 'Transient',
  Overnight = 'Overnight',
}

export enum DashboardView {
  TODO = 'todo',
  PARKED = 'parked guests',
  TOPARK = 'send to park',
  TOPROP = 'send to property',
}

export enum VisitCardActionButtonType {
  SendToHotel = 'Send to Hotel',
  GuestRecievedVehicle = 'Guest Recieved Vehicle',
}

export type HospitalityStayDetails = {
  id: string;
  intakePartnerId: number;
  stayType: IdNameObject;
  siteId: number;
  active: boolean;
  visitStart: number;
  visitEnd: number | null;
  status: IdNameObject;
  isOversizedVehicle: boolean | null;
  guestFirstName: string | null;
  guestLastName: string | null;
  driverFirstName: string;
  driverLastName: string;
  hotelTicketNumber: string;
  hotelName: string;
  roomNumber: string | null;
  checkInDate: number | null;
  checkOutDate: number | null;
};

export type HospitalityVehicle = {
  id: number;
  licensePlate: LicensePlate;
};

export type HospitalityVehicleEvent = {
  id: string;
  hospitalityStayUuid: string;
  valetVisitUuid: string | null;
  pickUpRequestedAt: number | null;
  pickUpInProgressAt: number | null;
  parkedAt: number | null;
  dropOffRequestedAt: number | null;
  dropOffInProgressAt: number | null;
  droppedOffAt: number | null;
};

export type HospitalityStay = {
  hospitalityStay: HospitalityStayDetails;
  vehicle?: HospitalityVehicle;
  vehicleEvent?: HospitalityVehicleEvent;
};

export enum VisitCardVariant {
  SearchResult = 'search result',
}

export type VisitCardProps = {
  details: HospitalityStay;
  variant?: VisitCardVariant;
};

export enum StaySiteType {
  OffSite = 'Off-site',
  OnSite = 'On-site',
}
